import BicolorBackgroundBox from '@fingo/lib/components/boxes/BicolorBackgroundBox';
import FingoCircularProgressLoader from '@fingo/lib/components/loader/FingoCircularProgressLoader';
import MutationNotification from '@fingo/lib/components/notifications/MutationNotification';
import { useIsMobile } from '@fingo/lib/hooks';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { PropTypes } from 'prop-types';
import React from 'react';

const FingoNotificationPanel = ({ loading, messageParagraph }) => {
  const isMobile = useIsMobile();

  return (
    <Stack minHeight="100%">
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '10px',
          background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main} 60%)`,
          position: 'relative',
        })}
      />
      <BicolorBackgroundBox
        paddingTop={isMobile ? 2 : 4}
        height={isMobile ? '100%' : ''}
        flexGrow="1"
        px="4%"
        sx={(theme) => ({
          background: isMobile
            ? theme.palette.text.main
            : `linear-gradient(${theme.palette.text.main} 50%, ${theme.palette.background.dark} 30%)`,
        })}
      >
        <Paper sx={{ backgroundColor: 'white', justifyContent: 'center', borderRadius: 4, height: '85vh' }}>
          {loading ? <FingoCircularProgressLoader /> : (
            <MutationNotification
              messageParagraph={messageParagraph}
            />
          )}
        </Paper>
      </BicolorBackgroundBox>
    </Stack>
  );
};

FingoNotificationPanel.propTypes = {
  loading: PropTypes.bool,
  messageParagraph: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
  }),
};

FingoNotificationPanel.defaultProps = {
  loading: true,
  messageParagraph: {
    header: '',
    body: '',
  },
};

export default FingoNotificationPanel;
