import Slider from '@mui/material/Slider';
import styled from '@mui/styles/styled';

const FingoSlider = styled(Slider)(({ theme }) => ({
  height: 8,
  '& .MuiSlider-thumb': {
    height: 24,
    width: 8,
    borderRadius: 4,
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'bold',
    top: -6,
    backgroundColor: 'unset',
  },
  '& .MuiSlider-valueLabelLabel': {
    color: theme.palette.primary.main,
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: theme.palette.text.primary,
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.background.dark,
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },
  '& .MuiSlider-markLabel': {
    color: theme.palette.text.primary,
    fontWeight: '400',
  },
}));

export default FingoSlider;
